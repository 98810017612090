import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Pricing from "./Pages/Pricing";
import MainLayout from "./Pages/MainLayout";
import Signup from "./Pages/Signup";
import Contactus from "./Pages/Contactus";
import Features from "./Pages/Features";
import Faq from "./Pages/Faq";
import Portfolio from "./Pages/Portfolio";
import PortfolioDetail from "./Pages/PortfolioDetail";
import PortfolioDetail_2 from "./Pages/PortfolioDetail_2";
import Blogdetail from "./Pages/Blogdetail";
import PortfolioDetail_3 from "./Pages/PortfolioDetail_3";
import Blog from "./Pages/Blog";
import Zumkha_Detail from "./Pages/ZumkhaDetail_backup";
import Kishoricreation_Detail from "./Pages/Kishoricreation_Detail";
import YashIndustries_Detail from "./Pages/YashIndustries_Detail";
import UnibestDetail_backup from "./Pages/UnibestDetail_backup";
import DPDecor_Detail_backup from "./Pages/DPDecor_Detail_backup";
import LaxmirajDetail_backup from "./Pages/LaxmirajDetail_backup";
import NewSignup from "./Pages/newSignup";

function App() {
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/signup" element={<NewSignup />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/features" element={<Features />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfoliodetail" element={<PortfolioDetail />} />
          <Route path="/portfoliodetail_2" element={<PortfolioDetail_2 />} />
          <Route path="/blogdetail" element={<Blogdetail />} />
          <Route path="/portfoliodetail_3" element={<PortfolioDetail_3 />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/zumkha-detail" element={<Zumkha_Detail />} />
          <Route path="/kishori-detail" element={<Kishoricreation_Detail />} />
          <Route
            path="/YashIndustries_Detail"
            element={<YashIndustries_Detail />}
          />
          <Route path="/Unibest-Detail" element={<UnibestDetail_backup />} />
          <Route path="/dpdecor-detail" element={<DPDecor_Detail_backup />} />
          <Route path="/laxmiraj-detail" element={<LaxmirajDetail_backup />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
