import React, { Fragment, useState } from "react";
import signimg from "../images/les-img-2.png";
import recapchaimg from "../images/recapcha-img.svg";
import storeimg from "../images/store-register.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import vendor from "./property.json";
import PhoneInput from "react-phone-input-2";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { BiXCircle } from "react-icons/bi";
import ReCAPTCHA from "react-google-recaptcha";
import { IoEye } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import VerifyOtp from "./component/verifyOtp";
import errorimg from "../images/errorimg.png";

export const noOnlyNumbers = (value) => {
  return !/^\d+$/.test(value);
};

const noOnlySpecialCharacters = (value) => {
  return /[a-zA-Z0-9]/.test(value);
};

const noOnlySpecialCharactersAndNumbers = (value) => {
  return /[a-zA-Z]/.test(value);
};

const NewSignup = () => {
  let url = "https://server.launchestore.com/";
  // let url = "http://192.168.1.9:3001/";

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [passwordShow, setPasswordShow] = useState(false);
  const [allStores, setAllStores] = useState([]);
  const [files, setFiles] = useState({
    file: [],
    filePreview: "",
  });
  const [themes, setThemes] = useState([]);

  const validationSchemaStep1 = Yup.object().shape({
    domain_type: Yup.string().required(`${vendor.domain_type_error}`),
    domain_name: Yup.string()
      .required(`${vendor.domain_name_error}`)
      .test("domain-name-validation", function (value) {
        if (!value)
          return this.createError({ message: "Domain name is required" });

        const { domain_type } = this.parent; // Access domain_type from the same object

        // Common check: domain name should not contain only numbers or special characters
        if (!/^[a-zA-Z]/.test(value) || !/[a-zA-Z0-9]/.test(value)) {
          return this.createError({
            message:
              "Domain name cannot contain only numbers or special characters",
          });
        }

        // Additional validation for domain_type === "0"
        if (domain_type === "0") {
          const domainRegex =
            /^(\.?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,7})$/;
          const domainPart = value.startsWith(".")
            ? value.split(".")[1]
            : value.split(".")[0];

          if (
            !domainRegex.test(value) ||
            !/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/.test(domainPart)
          ) {
            return this.createError({
              message: "Invalid domain format",
            });
          }
        }

        return true; // Validation passed
      }),

    name: Yup.string()
      .required(`${vendor.shop_name_error}`)
      .test("no-only-numbers", `${vendor.shop_name_error_2}`, noOnlyNumbers)
      .test(
        "no-only-special-characters",
        `${vendor.shop_name_error_3}`,
        noOnlySpecialCharacters
      )
      .test(
        "no-only-special-characters-and-numbers",
        `${vendor.shop_name_error_4}`,
        (value) => noOnlySpecialCharactersAndNumbers(value)
      ),
    ownername: Yup.string()
      .required(`${vendor.shop_owner_name_error}`)
      .test(
        "no-only-numbers",
        `${vendor.shop_owner_name_error_2}`,
        noOnlyNumbers
      )
      .test(
        "no-only-special-characters",
        `${vendor.shop_owner_name_error_3}`,
        noOnlySpecialCharacters
      )
      .test(
        "no-only-special-characters-and-numbers",
        `${vendor.shop_owner_name_error_4}`,
        (value) => noOnlySpecialCharactersAndNumbers(value)
      ),
    email: Yup.string()
      .email(`${vendor.email_error_2}`)
      .required(`${vendor.email_error}`),
    image: Yup.mixed().required(`${vendor.shop_image_error}`),

    password: Yup.string()
      .required(`${vendor.password_error}`)
      .min(6, `${vendor.password_error2}`),
    mobile_number: Yup.string().required(vendor.phone_error),
    store_type: Yup.string().required(`${vendor.store_type_error}`),
    location: Yup.string().required(vendor.location_error),
  });

  const validationSchemaStep2 = Yup.object().shape({
    theme_name: Yup.string().required("Please select a theme"),
  });

  const formik1 = useFormik({
    initialValues: {
      domain_type: "0",
      domain_name: "",
      name: "",
      ownername: "",
      email: "",
      mobile_number: "",
      code: "91",
      password: "",
      confirm_Pass: "",
      image: "",
      store_type: "",
      location: "",
      theme_name: "",
      phone: "",
    },
    validationSchema: validationSchemaStep1,
    onSubmit: (values) => {
      if (emailerror === "" && phoneerror === "" && domainerror === "") {
        setStep(2);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      theme_name: "",
    },
    validationSchema: validationSchemaStep2,
    onSubmit: (values) => {
      if (isCaptchaValid) {
        formik2.setSubmitting(true);
        fetch(`${url}vendor/SendOtpMail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Remove this line for FormData
          },
          body: JSON.stringify({
            email: formik1.values.email,
            domain_name: formik1.values.domain_name,
            mobile_number: formik1.values.mobile_number,
          }), // Pass FormData as the body
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              setShowOTPModal(true);
            } else {
              setErrorModal(true);
              setErrorMessage(data.message);
              formik2.setSubmitting(false);
            }
          });
      } else {
        setIsOpen(true);
        // console.log(formDataObj, formData);
      }
    },
  });

  React.useEffect(() => {
    const fetchStore = async () => {
      try {
        const response = await fetch(`${url}allStoreType`);
        if (!response.ok) {
          throw new Error("Failed to fetch store");
        }
        const data = await response.json();
        setAllStores(data.data);
        if (data.data.length > 0) {
          formik1.setFieldValue("store_type", data.data[0]?.id?.toString());
        }
      } catch (error) {
        console.error("Error fetching store:", error);
      }
    };

    fetchStore();
  }, []);

  React.useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await fetch(`${url}allTheme`);
        if (!response.ok) {
          throw new Error("Failed to fetch themes");
        }

        const data = await response.json();
        setThemes(data.data); // Assuming the response has an array of themes
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchThemes();
  }, []);

  const handleSelect = (place) => {
    // Use the place_id from the selected place to get the location details
    geocodeByPlaceId(place.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        formik1.setFieldValue("location", place.label);
        formik1.setFieldValue("lng", lng);
        formik1.setFieldValue("lat", lat);
      });
  };

  const handleRemoveImg = () => {
    setFiles({ file: [], filePreview: "" });
    formik1.setFieldValue("image", "");
  };

  const [isOpen, setIsOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    formik2.setSubmitting(false);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [ErrorModal, setErrorModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [emailerror, setEmailError] = React.useState("");
  const [domainerror, setdomainError] = React.useState("");
  const [phoneerror, setphoneError] = React.useState("");

  const handleCaptchaChange = (value) => {
    if (value) {
      setIsCaptchaValid(true);
    }
  };

  const handleSubmit = (otp) => {
    let OtherData = {
      locality: "0",
      language_support: "0",
      login_type: 0,
      database: "",
      display_price_with_gst: "0",
      longitude: formik1.values.lng,
      latitude: formik1.values.lat,
      address: formik1.values.location,
      otp: otp,
    };
    // formData.mobile_number = `${formData.code}${formData.mobile_number}`;

    const completeData = { ...formik1.values, ...formik2.values, ...OtherData };

    // Create FormData object
    const formDataObj = new FormData();

    // Append each field to FormData
    for (const key in completeData) {
      formDataObj.append(key, completeData[key]);
    }

    // Use FormData in the fetch request
    fetch(`${url}vendor/createStore`, {
      method: "POST",
      headers: {
        enctype: "multipart/form-data",
      },
      body: formDataObj, // Pass FormData as the body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessModal(true);
          setShowOTPModal(false);
          formik2.setSubmitting(false);
        } else {
          setErrorModal(true);
          setErrorMessage(data.message);
          setShowOTPModal(false);
          formik2.setSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        formik2.setSubmitting(false);
      });
  };

  const checkValidparams = async (type) => {
    try {
      const params = {
        ...(type === "email" && { email: formik1.values.email }),
        ...(type === "phone" && {
          mobile_number: formik1.values.mobile_number,
        }),
        ...(type === "domain" && {
          domain_name:
            formik1.values.domain_type === "0"
              ? formik1.values.domain_name
              : `${formik1.values.domain_name}.launchestore.com`,
        }),
      };

      const response = await fetch(`${url}vendor/checkValidData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params), // Convert object to JSON string
      });

      if (!response.ok) {
        throw new Error("Failed to fetch store");
      }

      const data = await response.json(); // Parse the JSON response
      if (!data.success) {
        if (type === "domain") {
          setdomainError("Domain Name already Exists");
        } else if (type === "email") {
          setEmailError("Email already Exists");
        } else if (type === "phone") {
          setphoneError("Mobile Number already Exists");
        }
      }
    } catch (error) {
      console.error("Error fetching store:", error);
    }
  };

  return (
    <Fragment>
      <section id="cardSection" className="main-content main-les-form">
        <div className="row justify-content-center">
          <div className={`${step === 2 ? "d-none" : "col-lg-6"}`}>
            <div className="les-img">
              <img src={signimg} alt="signimg" />
            </div>
          </div>
          <div
            className={`${
              step === 2 ? "col-12" : "col-xl-6 col-lg-6 col-md-12"
            }`}
          >
            <div className={`${step === 2 ? "radio-tab-wrap" : "main-form"}`}>
              {step === 1 && (
                <form onSubmit={formik1.handleSubmit}>
                  <div className="form-radio">
                    <h5>Choose Domain</h5>
                    <div className="d-flex radio-wrap">
                      <div className="d-flex align-items-start">
                        <input
                          type="radio"
                          name="domain_type"
                          value="0"
                          className="form-check-input"
                          id="domain_type1"
                          checked={formik1.values.domain_type === "0"}
                          onChange={() =>
                            formik1.setFieldValue("domain_type", "0")
                          }
                          onBlur={formik1.handleBlur}
                        />
                        <label htmlFor={"domain_type1"}>
                          <h6>Use a New Domain </h6>
                          <p>
                            Register a brand-new domain for your online store.
                          </p>
                        </label>
                      </div>
                      <div className="d-flex align-items-start">
                        <input
                          type="radio"
                          name="domain_type"
                          value="1"
                          className="form-check-input"
                          id="domain_type2"
                          checked={formik1.values.domain_type === "1"}
                          onChange={() =>
                            formik1.setFieldValue("domain_type", "1")
                          }
                          onBlur={formik1.handleBlur}
                        />
                        <label htmlFor={"domain_type2"}>
                          <h6>Use a Free Subdomain</h6>
                          <p>
                            Register a brand-new domain for your online store.
                          </p>
                        </label>
                      </div>
                    </div>
                    {formik1.touched.domain_type &&
                      formik1.errors.domain_type && (
                        <div className="error" style={{ color: "red" }}>
                          {formik1.errors.domain_type}
                        </div>
                      )}
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-form">
                        <input
                          type="text"
                          name="domain_name"
                          placeholder="Domain Name"
                          maxLength={150}
                          style={{ position: "relative" }}
                          value={formik1.values.domain_name}
                          onChange={(e) => {
                            formik1.setFieldValue(
                              "domain_name",
                              e.target.value
                            );
                            setdomainError("");
                          }}
                          onBlur={(e) => {
                            checkValidparams("domain");
                            formik1.handleBlur(e);
                          }}
                        />
                        {formik1.values.domain_type === "1" && (
                          <span className="subdomain_name_wrp">
                            .launchestore.com
                          </span>
                        )}
                        {formik1.touched.domain_name &&
                        formik1.errors.domain_name ? (
                          <div className="error">
                            {formik1.errors.domain_name}
                          </div>
                        ) : (
                          domainerror !== "" && (
                            <div className="error">{domainerror}</div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-xxl-6  col-md-6">
                      <div className="input-form">
                        {/* <label>Shop Name</label> */}
                        <input
                          type="text"
                          name="name"
                          placeholder="Shop Name"
                          maxLength={150}
                          value={formik1.values.name}
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                        />
                        {formik1.touched.name && formik1.errors.name && (
                          <div className="error">{formik1.errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-xxl-6  col-md-6">
                      <div className="input-form">
                        {/* <label>Owner Name</label> */}
                        <input
                          type="text"
                          name="ownername"
                          placeholder="Shop Owner Name"
                          maxLength={150}
                          value={formik1.values.ownername}
                          onChange={formik1.handleChange}
                          onBlur={formik1.handleBlur}
                        />
                        {formik1.touched.ownername &&
                          formik1.errors.ownername && (
                            <div className="error">
                              {formik1.errors.ownername}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-xxl-6  col-md-6">
                      <div className="input-form">
                        {/* <label>Phone Number</label> */}
                        <PhoneInput
                          country="in"
                          name="mobile_number"
                          placeholder="Phone Number"
                          onChange={(value, data) => {
                            formik1.setFieldValue("mobile_number", value);
                            formik1.setFieldValue("code", data.dialCode);
                            formik1.setFieldValue(
                              "phone",
                              value.slice(data.dialCode.length)
                            );
                            setphoneError("");
                          }}
                          countryCodeEditable={false}
                          value={formik1.values.mobile_number}
                          onBlur={(e) => {
                            formik1.handleBlur("mobile_number");
                            checkValidparams("phone");
                          }}
                        />
                        {formik1.touched.mobile_number &&
                        formik1.errors.mobile_number ? (
                          <div className="error">
                            {formik1.errors.mobile_number}
                          </div>
                        ) : (
                          phoneerror !== "" && (
                            <div className="error">{phoneerror}</div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-xxl-6  col-md-6">
                      <div className="input-form">
                        {/* <label>Email</label> */}
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          maxLength={150}
                          autoComplete="new-email"
                          value={formik1.values.email}
                          onChange={(e) => {
                            formik1.setFieldValue("email", e.target.value);
                            setEmailError("");
                          }}
                          onBlur={(e) => {
                            checkValidparams("email");
                            formik1.handleBlur(e);
                          }}
                        />
                        {formik1.touched.email && formik1.errors.email ? (
                          <div className="error">{formik1.errors.email}</div>
                        ) : (
                          emailerror !== "" && (
                            <div className="error">{emailerror}</div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                      <div className="input-form signup-password-wrp">
                        {/* <label>Password</label> */}
                        <div className="position-relative">
                          <input
                            type={passwordShow ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            maxLength={150}
                            autoComplete="off"
                            value={formik1.values.password}
                            onChange={formik1.handleChange}
                            onBlur={formik1.handleBlur}
                          />
                          <span
                            className={`fa fa-fw ${
                              passwordShow ? "fa-eye" : "fa-eye-slash"
                            } field-icon toggle-password`}
                            onClick={() => setPasswordShow(!passwordShow)}
                          ></span>
                        </div>
                        {formik1.touched.password &&
                          formik1.errors.password && (
                            <div className="error">
                              {formik1.errors.password}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                      <div className="input-form select-label">
                        {/* <label>Type</label> */}
                        <select
                          name="store_type"
                          id="store_type"
                          value={formik1.values.store_type}
                          onChange={(event) =>
                            formik1.setFieldValue(
                              "store_type",
                              event.target.value
                            )
                          }
                          onBlur={formik1.handleBlur}
                        >
                          <option value={""}>Select Store Type</option>
                          {allStores?.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {formik1.touched.store_type &&
                          formik1.errors.store_type && (
                            <div className="error">
                              {formik1.errors.store_type}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                      <div className="input-form location">
                        {/* <label>Location</label> */}
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyDPyeIABhKFMSFXrrcR1IE8jBUXJt-2bG4" // Replace with your actual API key
                          selectProps={{
                            defaultInputValue: formik1.values.location,
                            placeholder: "Search Store Location...",
                            onChange: (place) => {
                              handleSelect(place);
                              formik1.setFieldValue("location", place.label);
                            },
                            name: "location",
                            onBlur: formik1.handleBlur,
                          }}
                          value={formik1.values.location}
                        />
                        {formik1.touched.location &&
                          formik1.errors.location && (
                            <div className="error">
                              {formik1.errors.location}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                      {files.file.length === 0 && (
                        <div className="input-form position-relative">
                          {/* <label>image</label> */}
                          <input
                            type="file"
                            onBlur={formik1.handleBlur}
                            onChange={(event) => {
                              formik1.setFieldValue(
                                "image",
                                event.currentTarget.files[0]
                              );
                              setFiles({
                                file: event.currentTarget.files,
                                filePreview: URL.createObjectURL(
                                  event.currentTarget.files[0]
                                ),
                              });
                            }}
                            className="custom-file-input"
                          />

                          {formik1.touched.image && formik1.errors.image && (
                            <div className="error">{formik1.errors.image}</div>
                          )}
                        </div>
                      )}
                      {files.file.length > 0 && (
                        <div className="signup-logo-img">
                          <label>Logo</label>
                          <img
                            src={files.filePreview}
                            alt=""
                            height={100}
                            width={100}
                          />
                          <BiXCircle
                            className="signup-img-close"
                            onClick={() => {
                              handleRemoveImg();
                              formik1.setFieldValue("image", ""); // Clear Formik field
                              formik1.validateField("image"); // Trigger validation explicitly
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <button className="btn next mt-4 w-100 " type="submit">
                      Choose a Theme{" "}
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_843_171)">
                          <path
                            d="M17.5 7L7.5 17"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M8.5 7H17.5V16"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_843_171">
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                              transform="translate(0.5)"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </form>
              )}
              {step === 2 && (
                <form onSubmit={formik2.handleSubmit}>
                  <div className="container">
                    <div className="d-flex align-items-center select-theme-header">
                      <span
                        className="cancel-btn back mt-5"
                        onClick={() => {
                          setStep(1);
                          formik2.setSubmitting(false);
                          setIsCaptchaValid(false);
                        }}
                      >
                        {" "}
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.6255 6.00033C14.6255 6.1578 14.5629 6.30883 14.4516 6.42018C14.3402 6.53153 14.1892 6.59408 14.0317 6.59408H2.40238L6.73305 10.924C6.78821 10.9792 6.83197 11.0447 6.86183 11.1167C6.89168 11.1888 6.90705 11.2661 6.90705 11.3441C6.90705 11.4221 6.89168 11.4993 6.86183 11.5714C6.83197 11.6435 6.78821 11.709 6.73305 11.7642C6.67788 11.8193 6.61239 11.8631 6.54031 11.8929C6.46823 11.9228 6.39098 11.9382 6.31297 11.9382C6.23495 11.9382 6.1577 11.9228 6.08562 11.8929C6.01355 11.8631 5.94805 11.8193 5.89289 11.7642L0.549139 6.42041C0.493934 6.36526 0.45014 6.29978 0.42026 6.2277C0.39038 6.15562 0.375 6.07836 0.375 6.00033C0.375 5.9223 0.39038 5.84504 0.42026 5.77296C0.45014 5.70088 0.493934 5.6354 0.549139 5.58025L5.89289 0.236502C6.0043 0.12509 6.15541 0.0625 6.31297 0.0625C6.47053 0.0625 6.62163 0.12509 6.73305 0.236502C6.84446 0.347914 6.90705 0.49902 6.90705 0.65658C6.90705 0.81414 6.84446 0.965247 6.73305 1.07666L2.40238 5.40658H14.0317C14.1892 5.40658 14.3402 5.46914 14.4516 5.58049C14.5629 5.69184 14.6255 5.84286 14.6255 6.00033Z"
                            fill="black"
                          />
                        </svg>{" "}
                        back
                      </span>
                      <h2>Choose Design Template</h2>
                    </div>

                    <div className="row">
                      {themes.length === 0 ? (
                        <p>Loading themes...</p>
                      ) : (
                        themes.map((theme, i) => (
                          <div className="col-lg-4 col-md-6 mt-4">
                            <label className="custom-radio" key={i}>
                              {/* Normal HTML radio input */}
                              <input
                                type="radio"
                                name="theme_name"
                                value={theme.name} // Use theme name or unique value
                                checked={
                                  formik2.values.theme_name === theme.name
                                } // Formik state tracking
                                onChange={(e) => {
                                  formik2.setFieldValue(
                                    "theme_name",
                                    e.target.value
                                  );
                                }} // Manually set Formik value
                              />
                              <span className="radio-btn card">
                                <svg
                                  className="check"
                                  width="25"
                                  height="24"
                                  viewBox="0 0 25 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.46875"
                                    width="24"
                                    height="24"
                                    rx="12"
                                    fill="#2DCA76"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.2124 6.78007C19.3765 6.95945 19.4688 7.20271 19.4688 7.45635C19.4688 7.71 19.3765 7.95326 19.2124 8.13264L10.8934 17.2199C10.7291 17.3993 10.5065 17.5 10.2743 17.5C10.0421 17.5 9.81936 17.3993 9.65514 17.2199L5.71457 12.9154C5.55505 12.735 5.46679 12.4934 5.46878 12.2426C5.47078 11.9918 5.56287 11.7519 5.72523 11.5745C5.88759 11.3972 6.10722 11.2966 6.33683 11.2944C6.56643 11.2922 6.78763 11.3886 6.95278 11.5629L10.2743 15.1911L17.9741 6.78007C18.1384 6.60074 18.3611 6.5 18.5933 6.5C18.8255 6.5 19.0481 6.60074 19.2124 6.78007Z"
                                    fill="white"
                                  />
                                </svg>
                                <div className="hobbies-icon">
                                  <button
                                    type="button"
                                    className="hobbies-view-theme-btn"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      window.open(
                                        theme.preview_url,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }}
                                  >
                                    <IoEye />
                                  </button>
                                  <img
                                    width={150}
                                    src={theme.themeImageUrl}
                                    alt={theme.name}
                                  />
                                </div>
                                <h3
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    window.open(
                                      theme.preview_url,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                >
                                  {theme.name}
                                </h3>
                              </span>
                            </label>
                          </div>
                        ))
                      )}
                    </div>
                    <div className="col-xxl-12 google-recaptcha-wrp mt-5 text-center">
                      <ReCAPTCHA
                        sitekey="6Lfjv3MmAAAAAKl2fYbW1RSBe5MlcUsfobAHUUfs" // Replace with your site key
                        onChange={handleCaptchaChange}
                      />
                    </div>
                    {/* <ErrorMessage name="theme_name" component="div" /> */}
                    {formik2.touched.theme_name &&
                      formik2.errors.theme_name && (
                        <div className="error" style={{ color: "red" }}>
                          {formik2.errors.theme_name}
                        </div>
                      )}
                    <div className="button-wrapper text-center">
                      <button
                        type="submit"
                        className="btn next mt-4 submit-button"
                        disabled={!formik2.dirty || formik2.isSubmitting}
                      >
                        Create Store
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>

      {ErrorModal && (
        <div className="success-model-wrp">
          <div className="success-modal">
            <div className="success-img text-center">
              <img src={errorimg} alt="errorimg" />
            </div>
            <h4>Error</h4>
            <p>{errorMessage}</p>
            <span
              onClick={() => {
                setStep(1);
                setErrorModal(false);
                setErrorMessage("");
              }}
              className="success-ok-btn"
            >
              ok
            </span>
          </div>
        </div>
      )}
      {showOTPModal && (
        <VerifyOtp
          show={showOTPModal}
          onHide={() => {
            formik2.setSubmitting(false);
            setShowOTPModal(false);
          }}
          handleSubmit={handleSubmit}
        />
      )}

      {/* <!-- recapcha Modal --> */}
      {isOpen && (
        <div
          className="modal fade show d-block recapcha-model"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          role="dialog"
          onClick={closeModal}
        >
          <div
            class="modal-dialog modal-dialog-centered"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.13642 1.13642C1.89643 0.376402 3.12869 0.376402 3.88863 1.13642L8.99977 6.24763L14.1109 1.13647C14.871 0.376454 16.103 0.376454 16.863 1.13647C17.6231 1.89649 17.6231 3.12874 16.863 3.88889L11.7522 8.99977L16.863 14.1106C17.6231 14.8707 17.6231 16.103 16.863 16.863C16.103 17.6231 14.8707 17.6231 14.1106 16.863L8.99977 11.7522L3.88889 16.863C3.12874 17.6231 1.89649 17.6231 1.13647 16.863C0.376454 16.103 0.376454 14.871 1.13647 14.1109L6.24763 8.99977L1.13642 3.88863C0.376402 3.12869 0.376402 1.89643 1.13642 1.13642Z"
                      fill="#040404"
                    />
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <div className="recapcha-img">
                  <img src={recapchaimg} alt="signimg" />
                </div>
                <h4>Please select Recaptcha</h4>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn ok-btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  Okay{" "}
                  <span>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.6414 8.00076H1.49924"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.27734 1.63672L15.6413 8.00068L9.27734 14.3646"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <!-- onlinestore Modal --> */}
      {successModal && (
        <div
          className="modal fade show d-block  recapcha-model store-modal "
          tabIndex="-1"
          role="dialog"
          aria-labelledby={"aaasdasdasdassdassdasd"}
          aria-hidden={!successModal}
          onClick={() => navigate("/")}
        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => navigate("/")}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.13642 1.13642C1.89643 0.376402 3.12869 0.376402 3.88863 1.13642L8.99977 6.24763L14.1109 1.13647C14.871 0.376454 16.103 0.376454 16.863 1.13647C17.6231 1.89649 17.6231 3.12874 16.863 3.88889L11.7522 8.99977L16.863 14.1106C17.6231 14.8707 17.6231 16.103 16.863 16.863C16.103 17.6231 14.8707 17.6231 14.1106 16.863L8.99977 11.7522L3.88889 16.863C3.12874 17.6231 1.89649 17.6231 1.13647 16.863C0.376454 16.103 0.376454 14.871 1.13647 14.1109L6.24763 8.99977L1.13642 3.88863C0.376402 3.12869 0.376402 1.89643 1.13642 1.13642Z"
                      fill="#040404"
                    />
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <div className="recapcha-img">
                  <img src={storeimg} alt="storeimg" />
                </div>
                <h4>Your Online Store is Ready to Shine!</h4>
                <p>
                  You have successfully been registered to LaunchEStore. Your
                  login credentials are provided below:
                </p>
                <div>
                  <h5>
                    Domain name:{" "}
                    <a
                      href={
                        formik1.values.domain_type === "0"
                          ? `https://${formik1.values.domain_name}`
                          : `https://${formik1.values.domain_name}.launchestore.com`
                      }
                    >
                      {formik1.values.domain_type === "0"
                        ? `https://${formik1.values.domain_name}`
                        : `https://${formik1.values.domain_name}.launchestore.com`}
                    </a>
                  </h5>
                  <h5>
                    Email: <a href="#">{formik1.values.email}</a>
                  </h5>
                  <h5>
                    Password: <a href="#">{formik1.values.password}</a>
                  </h5>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn ok-btn btn-secondary"
                  onClick={() => navigate("/")}
                >
                  Go to Home{" "}
                  <span>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.6414 8.00076H1.49924"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.27734 1.63672L15.6413 8.00068L9.27734 14.3646"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default NewSignup;
